<template>
  <div>
    <van-action-sheet
      v-model="boxShow"
      :round="false"
      :lock-scroll="true"
      :close-on-click-overlay="false"
    >
      <div class="hidden_box" v-if="boxShow">
        <div class="close_hidden_box" @click="hidenBox()">
          <van-icon name="cross" />
        </div>

        <div class="production_box">
          <div class="product_pic">
            <img :src="goodsItem.goodsInfo.picHead" alt="图片描述" />
          </div>
          <div class="product_texe">
            <p class="title">{{ goodsItem.goodsInfo.title }}</p>
            <span>产品编号：{{ goodsItem.goodsInfo.deviceNo }}</span>
          </div>
        </div>
        <van-divider class="divider_line" />
        <div class="price">
          <p>&yen;{{ goodsItem.price }}</p>
        </div>
        <ul>
          <li @click="changePay(2)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>余额支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 2 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
          <li @click="changePay(1)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>微信支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 1 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
          <li @click="changePay(0)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>支付宝支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 0 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
        </ul>
        <div class="save_btn" @click="payOrder()">
          <button>确定支付</button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import {orderPayment, findOrder} from "@/utils/api";
import {Toast} from "vant";
export default {
  data() {
    return {
      changeIndex: 2,
    };
  },
  props: {
    boxShow: {
      type: Boolean,
    },
    goodsItem: {
      type: Object,
    },
  },
  watch: {
    goodsItem: {
      handler(newName, oldName) {
        console.log(oldName, "in");
        return newName;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    hidenBox() {
      this.$emit("hidenBox");
    },
    changePay(i) {
      this.changeIndex = i;
    },
    payOrder() {
      let that = this;
      Toast.loading({
        message: "正在支付，请稍等...",
        forbidClick: true,
        duration: 0,
      });
      // 支付接口
      setTimeout(() => {
        let obj = {
          orderNo: that.goodsItem.orderNo,
          price: that.goodsItem.price,
          orderType: 1,
        };
        window.localStorage.setItem("productionitem", JSON.stringify(obj));
        if (that.changeIndex == 2) {
          orderPayment({
            payType: that.changeIndex,
            orderNo: that.goodsItem.orderNo,
            channel: "APP",
            orderType: 1,
          })
            .then((res) => {
              setTimeout(() => {
                findOrder({orderNo: that.goodsItem.orderNo})
                  .then((success) => {
                    Toast.clear();
                    if (success.map.status == "S") {
                      that.$router.push({
                        path: "/pay",
                        query: {
                          type: "success",
                        },
                      });
                      this.boxShow = false;
                    } else {
                      that.$router.push({
                        path: "/pay",
                        query: {
                          type: "error",
                        },
                      });
                      this.boxShow = false;
                    }
                  })
                  .catch();
                console.log(res);
              }, 1000);
            })
            .catch(() => {});
        } else {
          Toast.clear();
          window.android.pay(that.goodsItem.orderNo, that.changeIndex, 1);
        }
      }, 200);
      //   orderPayment({
      //     payType: that.changeIndex,
      //     orderNo: that.goodsItem.orderNo,
      //     channel: "APP",
      //     orderType: 1,
      //   })
      //     .then((res) => {
      //       console.log(res);
      //     })
      //     .catch(() => {});
      // }, 200);

      // if (that.changeIndex == 2) {
      //   console.log("余额支付");
      // } else if (that.changeIndex == 1) {
      //   console.log("微信支付");
      // } else if (that.changeIndex == 0) {
      //   console.log("支付宝支付");
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.divider_line {
  margin: 0 30px !important;
  background-color: #374372;
  //   padding: 0 auto;
  color: #374372;
  border-color: #374372;
}
.hidden_box {
  background-color: #232a44;
  position: relative;
  .close_hidden_box {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 40px;
    color: #acafb8;
  }
  .production_box {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: flex-end;
    padding: 80px 0 40px;
    .product_pic {
      width: 120px;
      height: 120px;
    }
    .product_texe {
      width: 80%;
      margin-left: 30px;
      .title {
        color: #00ccff;
        font-size: 28px;
        margin-bottom: 10px;
      }
      span {
        color: #37708f;
        font-size: 24px;
      }
    }
  }
  .price {
    width: 90%;
    margin: 0 auto;
    p {
      color: #00ccff;
      font-size: 56px;
      padding: 50px 0;
      text-align: center;
    }
  }
  ul {
    width: 90%;
    margin: 0 auto;
    li {
      width: 100%;
      height: 119px;
      line-height: 119px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .letf_text {
        .icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          margin-right: 20px;
          background-size: 100% 100%;
          vertical-align: sub;
        }

        p {
          display: inline-block;
          color: #00ccff;
          font-size: 28px;
        }
      }

      .radio_icon {
        .icon {
          display: inline-block;
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .checked_true {
          background-image: url("../../assets/address/checked_true.png");
        }
        .checked_false {
          background-image: url("../../assets/address/checked_false.png");
        }
      }
    }
    li:first-of-type > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon1.png");
    }
    li:nth-of-type(2) > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon2.png");
    }
    li:last-of-type > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon3.png");
    }
  }
  .save_btn {
    widows: 100%;
    text-align: center;
    button {
      width: 90%;
      background: #00ccff;
      border-radius: 44px;
      height: 88px;
      text-align: center;
      border: none;
      outline: none;
      color: #fff;
      font-size: 28px;
      margin: 50px auto 40px;
    }
  }
}
</style>
