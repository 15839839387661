<template>
  <div class="web_box">
    <van-nav-bar
      title="我的订单"
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <div class="tab_list">
      <van-tabs
        v-model="active"
        background="#12172c"
        title-active-color="#00CCFF"
        title-inactive-color="#37708F"
        color="#00CCFF"
        line-height="2"
        @change="changeTab(active)"
      >
        <van-tab
          v-for="item in navLst"
          :title="item.name"
          :name="item.active"
          :key="item.id"
        ></van-tab>
        <!-- <van-tab title="全部" name="1"></van-tab>
        <van-tab title="代付款" name="2"></van-tab>
        <van-tab title="代发货" name="3"></van-tab>
        <van-tab title="待收货" name="4"></van-tab>
        <van-tab title="已完成" name="6"></van-tab> -->
      </van-tabs>
    </div>
    <div class="order_list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="order_item" v-for="item in orderList" :key="item.id">
          <p class="created_time">{{ item.createtime }}</p>
          <div class="order_details">
            <p class="order_number">订单编号：{{ item.orderNo }}</p>
            <span class="order_status">{{ item.statusName }}</span>
          </div>
          <div class="order_box">
            <div class="order_pic" @click="toDetails(item.goodsInfo.id)">
              <img :src="item.goodsInfo.picHead" alt="pic" />
            </div>
            <div class="order_content">
              <h3 class="order_title">{{ item.goodsInfo.title }}</h3>
              <p class="pro_num">产品编号：{{ item.goodsInfo.deviceNo }}</p>
              <div class="price_box">
                <span class="order_price"
                  >&yen; <i>{{ item.goodsInfo.price }}</i></span
                >
                <span class="order_count">x{{ item.goodsInfo.buyNum }}</span>
              </div>
            </div>
          </div>
          <van-divider class="divider_line" />
          <div class="all_count">
            共{{ item.buyNum }}件商品 合计：&yen; {{ item.totalPrice }}
          </div>
          <div v-for="(btnItem, index) in btnList" :key="index">
            <div class="btn_list" v-show="btnItem.status == item.status">
              <button
                v-for="(btn, index) in btnItem.btnlist"
                :key="index"
                @click="toDoThing(btn, item)"
              >
                {{ btn }}
              </button>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <payBox
      :boxShow="boxShow"
      @hidenBox="hidenBox"
      :goodsItem="goodsItem"
    ></payBox>
  </div>
</template>
<script>
import {
  queryAllOrder,
  cancelOrder,
  remindTheDelivery,
  orderConfirm,
} from "@/utils/api";
import payBox from "../../components/pay/payBox";
import {Toast, Dialog} from "vant";
export default {
  components: {
    payBox,
  },
  data() {
    return {
      active: "",
      loading: false,
      finished: false,
      total: "",
      parame: {
        page: 0,
        limit: 10,
        status: "",
      },

      navLst: [
        {name: "全部", active: "", id: 1},
        {name: "待付款", active: "D", id: 2},
        {name: "待发货", active: "S", id: 3},
        {name: "待收货", active: "R", id: 4},
        {name: "已完成", active: "E", id: 5},
      ],
      orderList: "",
      btnList: [
        {
          status: "D",
          btnlist: ["取消订单", "立即支付"],
        },
        {status: "S", btnlist: ["取消订单", "提醒发货", "查看物流"]},
        {status: "R", btnlist: ["确认收货", "查看物流"]},
        {status: "E", btnlist: ["再次购买"]},
      ],
      boxShow: false, //支付弹窗
      goodsItem: {}, //点击的对象
    };
  },
  mounted() {
    this.orderList = [];
    this.parame.page = 0;
    this.loading = true;
    this.finished = false;
    this.onLoad();
  },
  methods: {
    changeTab(active) {
      this.orderList = [];
      this.parame.page = 0;
      this.parame.status = active;
      this.loading = true;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      // 异步更新数据
      this.parame.page++;
      queryAllOrder(this.parame)
        .then((res) => {
          let rows = res.data.list; //请求返回当页的列表
          this.loading = false;
          this.total = res.data.totalCount;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.orderList = this.orderList.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.orderList.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {});
    },
    toDoThing(text, item) {
      if (text == "查看物流") {
        this.$router.push({
          path: "/logistics",
          query: {orderNo: item.orderNo},
        });
      } else if (text == "取消订单") {
        this.cancelOrderEvent(item);
      } else if (text == "立即支付") {
        this.toPay(item);
      } else if (text == "提醒发货") {
        this.remindShipment(item);
      } else if (text == "确认收货") {
        this.makeComfirm(item);
      } else if (text == "再次购买") {
        this.$router.push({
          path: "/confirmOrder",
          query: {
            buyNum: 1,
            goodsId: item.goodsInfo.id,
          },
        });
      }
    },
    // 取消订单事件
    cancelOrderEvent(item) {
      Dialog.confirm({
        title: "",
        message: "确认取消该订单？",
      })
        .then(() => {
          // 取消订单
          cancelOrder({
            orderNo: item.orderNo,
          })
            .then(() => {
              Toast("订单已取消");
              this.changeTab(this.active);
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 立即支付
    toPay(item) {
      let that = this;
      window.localStorage.removeItem("productionitem");
      that.goodsItem = item;
      console.log(that.goodsItem);
      that.boxShow = true;
    },
    makeComfirm(item) {
      Dialog.confirm({
        title: "",
        message: "确认收货？",
      })
        .then(() => {
          orderConfirm({
            orderNo: item.orderNo,
          })
            .then(() => {
              Toast("订单已收货");
              this.changeTab(this.active);
            })
            .catch();
        })
        .catch(() => {});
    },
    // 隐藏支付框
    hidenBox() {
      this.boxShow = false;
    },
    // 提醒发货
    remindShipment(item) {
      Toast.loading({
        message: "",
        duration: 500,
      });
      setTimeout(() => {
        remindTheDelivery({
          orderNo: item.orderNo,
        })
          .then((res) => {
            console.log(res);
          })
          .catch(() => {});
      }, 500);
    },
    // 去详情
    toDetails(id) {
      this.$router.push({
        path: "productionDetails",
        query: {
          id: id,
          type: 1,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.tab_list {
  width: 100%;
  height: 88px;
  margin-top: 88px;
  background-color: #12172c;
  position: fixed;
  top: 0px;
  z-index: 1;
}
.order_list {
  width: 100%;
  position: relative;
  margin-top: 196px;
  .order_item {
    width: 87%;
    margin: 20px auto 0;
    background: #12172c;
    border-radius: 10px;
    padding: 40px 30px;
    .created_time {
      font-size: 22px;
      color: #374372;
      line-height: 30px;
    }
    .order_details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .order_number {
        font-size: 26px;
        line-height: 37px;
        color: #7f89b3;
      }
      .order_status {
        font-size: 30px;
        color: #00ccff;
        line-height: 42px;
      }
    }
    .order_box {
      display: flex;
      justify-content: left;
      margin-top: 41px;
      .order_pic {
        width: 160px;
        height: 160px;
        background: #374372;
      }
      .order_content {
        margin-left: 30px;
        flex: 1;
        .order_title {
          font-size: 28px;
          color: #fff;
          line-height: 40px;
        }
        .pro_num {
          margin-top: 18px;
          color: #37708f;
          font-size: 27px;
          line-height: 33px;
        }
        .price_box {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .order_count {
            font-size: 26px;
            color: #fff;
            line-height: 37px;
          }
          .order_price {
            margin-top: 20px;
            font-size: 26px;
            color: #00ccff;
            font-weight: bold;
            i {
              font-size: 40px;
              font-style: normal;
            }
          }
        }
      }
    }
  }
  .divider_line {
    // color: #374372;
    background-color: #374372;
    color: #374372;
    border-color: #374372;
    padding: 0 0;
    margin: 47px 0 24px;
  }
  .all_count {
    font-size: 26px;
    color: #fff;
    line-height: 37px;
    text-align: right;
  }
  .btn_list {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: 1px solid #7f89b3;
      color: #7f89b3;
      width: 150px;
      height: 60px;
      font-size: 26px;
      line-height: 60px;
      border-radius: 44px;
      margin-left: 15px;
      background-color: transparent;
    }
    button:last-of-type {
      background-color: #00ccff;
      color: #ffffff;
      border: 1px solid #00ccff;
    }
  }
}
</style>
<style lang="scss">
.van-tab__text {
  font-size: 26px;
}
</style>
